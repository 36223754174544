import { type ClassValue, clsx } from "clsx";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function useOutsideClick(ref: any, callback: () => void) {
  useEffect(() => {
    // Function to detect click event
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

export async function getGeolocation() {
  const response = await fetch("https://ipapi.co/json/");
  if (!response.ok) {
    throw new Error("Failed to fetch geolocation data");
  }
  return response.json();
}

export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value); // Update debounced value after delay
    }, delay);

    // Cleanup the timeout if value changes before the delay completes
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
}

export type FieldErrors = Record<string, string[]>;

export function validateForm<T extends z.ZodTypeAny>(
  formData: FormData,
  schema: T
):
  | {
      data: z.infer<T>;
      errors: null;
    }
  | {
      data: null;
      errors: FieldErrors;
    } {
  const result = schema.safeParse(Object.fromEntries(formData));

  if (result.success) {
    return { data: result.data, errors: null };
  } else {
    const formattedErrors: FieldErrors = {};
    Object.entries(result.error.formErrors.fieldErrors).forEach(
      ([key, value]) => {
        formattedErrors[key] = value || [];
      }
    );
    return {
      data: null,
      errors: formattedErrors,
    };
  }
}
